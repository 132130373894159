import './setupPublicPath'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'styles/normalize.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import { hexDecode } from 'utils/hexCode'

window.$t = (msgCode) => {
  // if (window.$locales && window.$locales[msgCode]) {
  //   let msg = hexDecode(window.$locales[msgCode])

  //   // @memo 業主名稱抽換
  //   msg = msg.replace(
  //     new RegExp('{owner}', 'g'),
  //     process.env.REACT_APP_CLIENT
  //   )
  //   msg = msg.replace(
  //     new RegExp('{REACT_APP_VENDOR_SHORT_NAME}', 'g'),
  //     process.env.REACT_APP_VENDOR_SHORT_NAME
  //   )

  //   if (msg === 'DEFAULT_EMPTY') return ''
  //   return msg
  // } else if (fallbackValue) {
  //   return fallbackValue
  // } else {
  //   return msgCode
  // }
  return msgCode
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
