import React, { lazy, Suspense } from 'react'
import './App.css'
import packageInfo from '../package.json'

console.info(`[relay] version ${packageInfo.version}`)

const Main = lazy(() => import(`./components/${process.env.REACT_APP_CLIENT}/Main`))

function App() {
  return (
    <Suspense fallback={<></>}>
      <Main />
    </Suspense>
  )
}

export default App
